import React from 'react';

function Hobbies() {
	return (
		<div className='page-container'>
			<section>
				<div className='section-container'>
					<h2 className='section-header'>hi.</h2>
					<div className='section-contents'>
						<div className='section-item'>Content</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Hobbies;
